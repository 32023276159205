<template>
  <div class="info_warp">
    <div class="wrap">

      <el-form :model="form">
        <el-row>
          <el-col :span="24" style="margin-bottom: 32rem;">
            <span style="color: #666;line-height: 36rem;font-weight: 400;font-size: 24rem;">系统已经根据您的做卷情况智能推送相应难度的题目，如果还需要进行难度调整，可在此功能进行设置！（0为系统设置）</span>
          </el-col>

          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id ==7)">
            <el-form-item label="语文">

              <el-slider v-model="difficultyData[0].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[0])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[0].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>

          </el-col>


          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id ==12)">
            <el-form-item label="数学">
              <el-slider v-model="difficultyData[8].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[8])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[8].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id ==11)">
            <el-form-item label="英语">
              <el-slider v-model="difficultyData[1].subject_difficulty_offsete"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[1])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[1].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id == 4)">
            <el-form-item label="物理">
              <el-slider v-model="difficultyData[2].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[2])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[2].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id == 9)">
            <el-form-item label="化学">
              <el-slider v-model="difficultyData[3].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[3])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[3].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id == 10)">
            <el-form-item label="生物学">
              <el-slider v-model="difficultyData[4].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[4])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[4].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id == 6)">
            <el-form-item label="历史">
              <el-slider v-model="difficultyData[5].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[5])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[5].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id ==5)">
            <el-form-item label="思想政治">
              <el-slider v-model="difficultyData[6].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[6])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[6].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="difficultyData && difficultyData.some(item => item.subject_id == 3)">
            <el-form-item label="地理">
              <el-slider v-model="difficultyData[7].subject_difficulty_offset"
                         :step="1"
                         :max="5"
                         @change="changeDiffculty(difficultyData[7])"
                         show-stops>
              </el-slider>
              <div class="star"
   >{{difficultyData[7].subject_difficulty_offset}} <img src="@/assets/icons/收藏实心.png"
                alt=""></div>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>
  </div>
</template>



<script>

import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      form: {

      },

      difficultyData: [

      ],
      gradeList: [],
      userInfo: {}
    }
  },
  created () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))



  },
  mounted () {

    this.$http({
      url: '/api/v1/user/get_difficulty',
      method: 'get',
      params: { grade_id: this.userInfo.edu_grade_id, }

    }).then(res => {
      this.difficultyData = res.data

    })
  },
  methods: {
    changeDiffculty (val) {
      console.log('changeDiffculty', val)
      this.$http({
        url: '/api/v1/user/difficulty',
        method: 'post',
        data: {
          subject_id: val.subject_id,
          subject_difficulty_offset: val.subject_difficulty_offset,
        }

      }).then(res => {

      })
    },

  }
}
</script>

<style lang="scss" scoped>
.info_warp {
  display: flex;
  justify-content: center;
  .wrap {
    width: 720rem;
    ::v-deep .el-form-item__content {
      margin-left: 150rem;
    }
    .star{
      position: absolute;
    top: 1rem;
    left: 598rem;
    width: 80rem;
    }
    .head_img {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32rem;
      .title {
        color: var(--light-333333, var(--neutral-color-333333, #333));
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px; /* 150% */
      }
      .text {
        color: var(--neutral-color-666666, #666);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
      }
    }
    .el-form {
      margin-top: 50rem;
      ::v-deep .el-form-item__label {
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        // line-height: 60rem;
      }
      ::v-deep .el-input--medium .el-input__inner {
        // height: 60rem;
        // line-height: 60rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
      }
    }

    .btn {
      width: 372rem;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 60rem;
      text-align: center;
    }
  }
}
.readonly {
  width: 100%;
  height: 60rem;
  z-index: 1999;
  position: absolute;
  top: 0;
}
</style>